var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backList(0)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.feeTypeListVisible,
            callback: function ($$v) {
              _vm.feeTypeListVisible = $$v
            },
            expression: "feeTypeListVisible",
          },
        },
        [
          _c("stamp-type", {
            attrs: {
              options: _vm.feeTypeList,
              radio: _vm.feeTypeSelect,
              title: "请选择类型",
            },
            on: { select: _vm.handleFeeTypeListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.coopListVisible,
            callback: function ($$v) {
              _vm.coopListVisible = $$v
            },
            expression: "coopListVisible",
          },
        },
        [
          _c("coops", {
            attrs: { coopId: _vm.coopIdSelect },
            on: { select: _vm.handleCoopListSelect },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "top", "get-container": "body" },
          model: {
            value: _vm.caseListVisible,
            callback: function ($$v) {
              _vm.caseListVisible = $$v
            },
            expression: "caseListVisible",
          },
        },
        [
          _c("case-list", {
            attrs: { caseId: _vm.caseId, isFinish: "NO" },
            on: { select: _vm.handleCaseListSelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "oa-caseInfo" },
        [
          _c(
            "div",
            {
              staticClass: "oa-caseInfo-caseName",
              on: { click: _vm.switchCaseList },
            },
            [
              _vm.caseName === ""
                ? _c("div", { staticStyle: { color: "#999" } }, [
                    _vm._v("请选择案件"),
                  ])
                : _c("div", [_vm._v(_vm._s(_vm.caseName))]),
            ]
          ),
          _vm.caseName !== ""
            ? _c("div", { staticClass: "oa-caseInfo-caseInfo" }, [
                _c("div", { staticClass: "caseInfo-casePirce" }, [
                  _c("div", [_vm._v(_vm._s(_vm.caseInfo.caseNo))]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.timestampToTime(_vm.caseInfo.acceptCaseTime)) +
                        "收案"
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "caseInfo-casePirce" }, [
                  _c("div", [
                    _vm._v(
                      "诉讼标的：" + _vm._s(_vm.caseInfo.objectOfAction || "无")
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "caseInfo-casePirce" }, [
                  _c("div", [
                    _vm._v(
                      "律师费/元：" +
                        _vm._s(_vm.caseInfo.legalCaseFinances.service)
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      "已收：" + _vm._s(_vm.caseInfo.legalCaseFinances.received)
                    ),
                  ]),
                  _c("div", [
                    _vm._v(" 未收："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        _vm._s(_vm.caseInfo.legalCaseFinances.uncollected)
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "caseInfo-switch-box" }, [
                  _c(
                    "div",
                    [
                      _c("div", [_vm._v("提交电子档案")]),
                      _c("van-switch", {
                        attrs: {
                          "active-color": "#409EFF",
                          "inactive-color": "#DCDFE6",
                          size: "16px",
                        },
                        model: {
                          value: _vm.caseInfo.isElectronicArchive,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseInfo, "isElectronicArchive", $$v)
                          },
                          expression: "caseInfo.isElectronicArchive",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("div", [_vm._v("提交纸质档案")]),
                      _c("van-switch", {
                        attrs: {
                          "active-color": "#409EFF",
                          "inactive-color": "#DCDFE6",
                          size: "16px",
                        },
                        model: {
                          value: _vm.caseInfo.isPaperArchive,
                          callback: function ($$v) {
                            _vm.$set(_vm.caseInfo, "isPaperArchive", $$v)
                          },
                          expression: "caseInfo.isPaperArchive",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div"),
              ])
            : _vm._e(),
          _vm.caseName !== ""
            ? _c("div", { staticClass: "oa-caseInfo-line" })
            : _vm._e(),
          _vm.caseName !== ""
            ? _c(
                "div",
                { staticClass: "oa-caseInfo-partition" },
                [
                  _c("div", { staticClass: "partition-title" }, [
                    _c(
                      "div",
                      { staticClass: "partition-title-price" },
                      [
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("费用分成"),
                        ]),
                        _c("van-field", {
                          attrs: {
                            placeholder: "请输入",
                            "input-align": "right",
                            type: "number",
                          },
                          on: { input: _vm.getNotAllocationMoney },
                          model: {
                            value: _vm.caseInfo.apportionMoney,
                            callback: function ($$v) {
                              _vm.$set(_vm.caseInfo, "apportionMoney", $$v)
                            },
                            expression: "caseInfo.apportionMoney",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(
                        " 未分：" +
                          _vm._s(
                            _vm.notAllocationMoney > 0
                              ? _vm.notAllocationMoney
                              : "0"
                          ) +
                          " "
                      ),
                    ]),
                    _c("i", {
                      staticClass: "iconfont icon-add",
                      on: { click: _vm.addFeeList },
                    }),
                  ]),
                  _vm._l(_vm.feeList, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "partition-content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "partition-content-user",
                            on: {
                              click: function ($event) {
                                return _vm.switchCoopList(item, i)
                              },
                            },
                          },
                          [
                            item.userName
                              ? _c("div", { staticClass: "img" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.userName.substring(
                                          item.userName.length - 2,
                                          item.userName.length
                                        ) || ""
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("div", { staticClass: "img" }),
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(item.userName || "请选择") + " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.switchFeeTypeList(item, i)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.feeTypeFmt(item.type))),
                            ]),
                            _c("van-icon", {
                              attrs: {
                                name: "arrow-down",
                                color: "#CCCCCC",
                                size: "16px",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { flex: "1", "margin-right": "20px" },
                          },
                          [
                            _c("van-field", {
                              attrs: {
                                placeholder: "请输入",
                                "input-align": "right",
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.getNotAllocationMoney(item, i)
                                },
                              },
                              model: {
                                value: item.money,
                                callback: function ($$v) {
                                  _vm.$set(item, "money", $$v)
                                },
                                expression: "item.money",
                              },
                            }),
                            _c("div", [_vm._v("(元)")]),
                          ],
                          1
                        ),
                        _c("div", { staticStyle: { "margin-right": "10px" } }, [
                          _vm._v(_vm._s(item.money / 10000) + "万元"),
                        ]),
                        _c("i", {
                          staticClass: "iconfont icon-delete",
                          on: {
                            click: function ($event) {
                              return _vm.deleteFeeList(item, i)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.progress === "UNAUDITED" ||
          _vm.progress === "APPROVED" ||
          _vm.progress === "CERTIFIED_FAILED"
            ? _c(
                "div",
                { staticClass: "oa-approval" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "oa-approval-title",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [_vm._v("审批记录")]
                  ),
                  _c("approval-record", {
                    attrs: {
                      typeId: this.id,
                      type: "LEGAL_CASE_PROGRESS_FINISHED_UPDATE",
                    },
                  }),
                ],
                1
              )
            : _vm.approvalUserComp
            ? _c("approval-user", {
                attrs: {
                  Approval: this.Approval,
                  CC: this.CC,
                  isAdd: _vm.isAdd,
                  approvalUserSource: _vm.approvalUser,
                  ccUserSource: _vm.ccUser,
                },
                on: { userSelect: _vm.userSelect, coopSelect: _vm.coopSelect },
              })
            : _vm._e(),
          _c("div", { staticClass: "oa-caseInfo-bottom" }, [
            _vm.isAdd === 1 &&
            (_vm.progress === "UNSUBMENT" ||
              _vm.progress === "CERTIFIED_FAILED")
              ? _c(
                  "button",
                  {
                    staticClass: "oa-button-save",
                    on: {
                      click: function ($event) {
                        return _vm.save("DELETE")
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                )
              : _vm._e(),
            _vm.progress === "CERTIFIED_FAILED" ||
            _vm.progress === "UNSUBMENT" ||
            (_vm.isAdd === 0 && this.Approval.length > 0 && this.CC.length > 0)
              ? _c(
                  "button",
                  {
                    staticClass: "oa-button-apply",
                    on: {
                      click: function ($event) {
                        return _vm.save("UNAUDITED")
                      },
                    },
                  },
                  [_vm._v(" 申请 ")]
                )
              : _vm._e(),
            _vm.isAdd === 1 &&
            _vm.btnList.length === 2 &&
            _vm.progress === "UNAUDITED"
              ? _c(
                  "button",
                  {
                    staticClass: "oa-button-delete",
                    on: {
                      click: function ($event) {
                        return _vm.save("CERTIFIED_FAILED")
                      },
                    },
                  },
                  [_vm._v(" 驳回 ")]
                )
              : _vm._e(),
            _vm.isAdd === 1 &&
            _vm.btnList.length === 2 &&
            _vm.progress === "UNAUDITED"
              ? _c(
                  "button",
                  {
                    staticClass: "oa-button-apply",
                    on: {
                      click: function ($event) {
                        return _vm.save("APPROVED")
                      },
                    },
                  },
                  [_vm._v(" 批准 ")]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
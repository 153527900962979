<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backList(0)"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <!-- <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button> -->
    </fb-header>
    <!-- 分成类型 -->
    <van-popup v-model="feeTypeListVisible" position="top" get-container="body">
      <stamp-type
        :options="feeTypeList"
        :radio="feeTypeSelect"
        title="请选择类型"
        @select="handleFeeTypeListSelect"
      />
    </van-popup>
    <!-- 协作人列表 -->
    <van-popup v-model="coopListVisible" position="top" get-container="body">
      <coops :coopId="coopIdSelect" @select="handleCoopListSelect" />
    </van-popup>
    <!-- 案件列表 -->
    <van-popup v-model="caseListVisible" position="top" get-container="body">
      <case-list
        :caseId="caseId"
        :isFinish="'NO'"
        @select="handleCaseListSelect"
      />
    </van-popup>

    <div class="oa-caseInfo">
      <div class="oa-caseInfo-caseName" @click="switchCaseList">
        <div v-if="caseName === ''" style="color:#999">请选择案件</div>
        <div v-else>{{ caseName }}</div>
      </div>
      <div class="oa-caseInfo-caseInfo" v-if="caseName !== ''">
        <div class="caseInfo-casePirce">
          <div>{{ caseInfo.caseNo }}</div>
          <div>{{ timestampToTime(caseInfo.acceptCaseTime) }}收案</div>
        </div>
        <div class="caseInfo-casePirce">
          <div>诉讼标的：{{ caseInfo.objectOfAction || '无' }}</div>
        </div>
        <div class="caseInfo-casePirce">
          <div>律师费/元：{{ caseInfo.legalCaseFinances.service }}</div>
          <div>已收：{{ caseInfo.legalCaseFinances.received }}</div>
          <div>
            未收：<span style="color:red">{{
              caseInfo.legalCaseFinances.uncollected
            }}</span>
          </div>
        </div>
        <div class="caseInfo-switch-box">
          <div>
            <div>提交电子档案</div>
            <van-switch
              v-model="caseInfo.isElectronicArchive"
              active-color="#409EFF"
              inactive-color="#DCDFE6"
              size="16px"
            />
          </div>
          <div>
            <div>提交纸质档案</div>
            <van-switch
              v-model="caseInfo.isPaperArchive"
              active-color="#409EFF"
              inactive-color="#DCDFE6"
              size="16px"
            />
          </div>
        </div>
        <div></div>
      </div>
      <div class="oa-caseInfo-line" v-if="caseName !== ''"></div>
      <div class="oa-caseInfo-partition" v-if="caseName !== ''">
        <div class="partition-title">
          <div class="partition-title-price">
            <span style="color:#999">费用分成</span>
            <van-field
              placeholder="请输入"
              input-align="right"
              type="number"
              @input="getNotAllocationMoney"
              v-model="caseInfo.apportionMoney"
            />
          </div>
          <div>
            未分：{{ notAllocationMoney > 0 ? notAllocationMoney : '0' }}
          </div>
          <i class="iconfont icon-add" @click="addFeeList"></i>
        </div>
        <div class="partition-content" v-for="(item, i) in feeList" :key="i">
          <div class="partition-content-user" @click="switchCoopList(item, i)">
            <div class="img" v-if="item.userName">
              {{
                item.userName.substring(
                  item.userName.length - 2,
                  item.userName.length
                ) || ''
              }}
            </div>
            <div class="img" v-else></div>
            <div>
              {{ item.userName || '请选择' }}
            </div>
          </div>
          <div @click="switchFeeTypeList(item, i)">
            <div>{{ feeTypeFmt(item.type) }}</div>
            <van-icon name="arrow-down" color="#CCCCCC" size="16px" />
          </div>
          <div style="flex:1;margin-right:20px">
            <van-field
              placeholder="请输入"
              input-align="right"
              type="number"
              v-model="item.money"
              @input="getNotAllocationMoney(item, i)"
            />
            <div>(元)</div>
          </div>
          <div style="margin-right:10px">{{ item.money / 10000 }}万元</div>
          <i class="iconfont icon-delete" @click="deleteFeeList(item, i)"></i>
        </div>
      </div>
      <!-- <div class="oa-caseInfo-line"></div>/4  -->
      <div
        class="oa-approval"
        v-if="
          progress === 'UNAUDITED' ||
            progress === 'APPROVED' ||
            progress === 'CERTIFIED_FAILED'
        "
      >
        <div class="oa-approval-title" style="margin-bottom:20px">审批记录</div>
        <approval-record
          :typeId="this.id"
          :type="'LEGAL_CASE_PROGRESS_FINISHED_UPDATE'"
        />
      </div>
      <approval-user
        v-else-if="approvalUserComp"
        :Approval="this.Approval"
        :CC="this.CC"
        :isAdd="isAdd"
        :approvalUserSource="approvalUser"
        :ccUserSource="ccUser"
        @userSelect="userSelect"
        @coopSelect="coopSelect"
      />
      <div class="oa-caseInfo-bottom">
        <button
          class="oa-button-save"
          @click="save('DELETE')"
          v-if="
            isAdd === 1 &&
              (progress === 'UNSUBMENT' || progress === 'CERTIFIED_FAILED')
          "
        >
          删除
        </button>
        <button
          class="oa-button-apply"
          @click="save('UNAUDITED')"
          v-if="
            progress === 'CERTIFIED_FAILED' ||
              progress === 'UNSUBMENT' ||
              (isAdd === 0 && this.Approval.length > 0 && this.CC.length > 0)
          "
        >
          申请
        </button>
        <button
          class="oa-button-delete"
          @click="save('CERTIFIED_FAILED')"
          v-if="isAdd === 1 && btnList.length === 2 && progress === 'UNAUDITED'"
        >
          驳回
        </button>
        <button
          class="oa-button-apply"
          @click="save('APPROVED')"
          v-if="isAdd === 1 && btnList.length === 2 && progress === 'UNAUDITED'"
        >
          批准
        </button>
      </div>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { mapState, mapActions, mapMutations } from 'vuex'
import CaseList from '../../components/caseList'
import StampType from '../../components/stampType'
import ApprovalRecord from '../../components/approvalRecord'
import ApprovalUser from '../../components/approvalUser'
import Coops from '../../components/coops'

export default {
  name: 'oa-caseInfo',
  mixins: [goBackMixin],
  components: {
    CaseList,
    StampType,
    ApprovalRecord,
    Coops,
    ApprovalUser
  },
  data() {
    return {
      id: 0,
      title: '结案申请',
      CC: [], // 抄送人列表
      Approval: [], // 审批人列表
      ccUser: {}, // 当前选中的抄送人
      approvalUser: {}, // 当前选中的审批人
      approvalUserComp: false, // 是否渲染审批人组件
      coopUser: null,
      caseTypeName: '其他非诉',
      isAdd: 0, // 0新增  1修改
      caseListVisible: false, // 案件列表弹框
      caseName: '', // 案件名称
      caseId: 0, // 案件id
      caseNo: '', // 案号
      caseInfo: {}, // 案件详情
      feeList: [], // 分摊人列表
      notAllocationMoney: 0, // 未分的钱
      feeTypeListVisible: false, // 分成类型弹框
      feeTypeList: [
        {
          value: 'LAWYER_EARNING',
          text: '律师收入'
        },
        {
          value: 'LAWOFFICE_EARNING',
          text: '律所收入'
        },
        {
          value: 'LAWOFFICE_GUARANTY',
          text: '律所保证金'
        },
        {
          value: 'NO_APPORTION_EXPENSE',
          text: '不分成支出'
        }
      ], // 分成类型列表
      feeTypeSelect: '',
      organiser: {},
      coopListVisible: false, // 协作人弹框
      coopIdSelect: 0, // 选中的协作人id
      progress: '',
      btnList: []
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo']),
    ...mapState('oa', ['oaType'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaType']),
    // 返回列表页面
    backList(time = 1000) {
      if (this.$router.length <= 1) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.setOaType('close')
        // window.localStorage.setItem('oaType', 'close')
        setTimeout(() => {
          this.$router.go(-1) // 返回上一层
        }, time)
      }
    },
    // 获取组织id
    async getId() {
      await this.getOrganizationId()
    },
    // 获取审批人和抄送人
    async getUsers() {
      this.approvalUserComp = false
      var params = {
        organizationId: this.organizationId,
        caseTypeName: this.caseTypeName,
        type: 'LEGAL_CASE_PROGRESS_FINISHED_UPDATE'
      }
      const res = await this.$axios.post(
        `${this.$base}/management/org/advice/user`,
        params
      )
      this.approvalUserComp = true
      const { code, data } = res.data
      if (code === 200 && data.length > 0) {
        const arr = [] // 审批
        const arrlist = [] // 抄送
        data.forEach(item => {
          if (item.type === 'APPROVER') {
            arr.push({
              label: item.userName,
              userid: item.userId,
              type: 'APPROVER',
              avatarUrl: item.userName
            })
          } else if (item.type === 'RECIPIENT') {
            arrlist.push({
              label: item.userName,
              userid: item.userId,
              type: 'RECIPIENT',
              avatarUrl: item.userName
            })
          }
        })
        this.CC = arrlist
        this.Approval = arr
        if (this.isAdd === 0) {
          this.ccUser = arrlist[0]
          this.approvalUser = arr[0]
        }
      }
    },
    /**
     * 处理审批流程组件返回的审批人和抄送人
     * 返回userList数组
     * 第一个元素是审批人
     * 第二个元素是抄送人
     */
    userSelect(userList) {
      this.approvalUser = userList[0]
      this.ccUser = userList[1]
    },
    // 协作人
    coopSelect(coopUser) {
      this.coopUser = coopUser
    },
    // 案件名称点击事件
    switchCaseList() {
      if (this.isAdd === 0) {
        this.caseListVisible = !this.caseListVisible
      }
    },
    handleCaseListSelect(options) {
      console.log(options)
      this.caseNo = options.caseNo
      this.caseName = options.label
      this.caseId = options.caseId
      // 根据案件id获取案件信息
      this.getCaseInfo(options.caseId)
      this.caseTypeName = options.caseTypeName
      this.getUsers()
      this.switchCaseList()
    },
    // 获取案件信息
    getCaseInfo(caseId) {
      // 根据案件id获取案件信息
      this.$axios
        .get(`${this.$base}/management/case/end/record/${caseId}`)
        .then(res => {
          if (res.data && res.data.code === 200) {
            this.caseInfo = res.data.data
            this.feeList = []
            this.caseNo = res.data.data.caseNo
            this.caseName = res.data.data.caseName
            const {
              legalCaseFinances,
              apportionMoney,
              caseFeeApportions,
              isElectronicArchive,
              isPaperArchive,
              approveRecordUsers,
              progress
            } = this.caseInfo
            if (apportionMoney === null || apportionMoney === '') {
              const f =
                Math.abs(legalCaseFinances.receivable) -
                Math.abs(legalCaseFinances.received)
              this.caseInfo.apportionMoney = f
            }
            this.caseInfo.isElectronicArchive = isElectronicArchive === 'YES'
            this.caseInfo.isPaperArchive = isPaperArchive === 'YES'
            if (caseFeeApportions && caseFeeApportions.length) {
              this.feeList = caseFeeApportions.map(item => {
                const t = { ...item, mark: `${Date.now()}${item.id}` }
                return t
              })
            } else {
              const temp = {
                caseFeeDivisionMode: 'QUOTA',
                ratio: null,
                money: this.caseInfo.apportionMoney,
                userId: this.organiser.id,
                type: 'LAWYER_EARNING',
                target: 'LAWYER',
                userName: this.organiser.nickName
              }
              temp.mark = `${Date.now()}${this.markNum}`
              this.feeList = [...this.feeList, temp]
            }
            if (progress) {
              this.progress = progress
              for (var k = 0; k < approveRecordUsers.length; k++) {
                if (
                  approveRecordUsers[k].type === 'APPLICANT' &&
                  approveRecordUsers[k].user.id === this.userInfo.id
                ) {
                  this.btnList = ['申请']
                  break
                }
              }
              for (var j = 0; j < approveRecordUsers.length; j++) {
                if (approveRecordUsers[j].type === 'APPROVER') {
                  if (approveRecordUsers[j].user.id === this.userInfo.id) {
                    this.btnList = ['批准', '驳回']
                    break
                  } else {
                    this.btnList = []
                  }
                }
              }
            }
            if (this.isAdd === 1) {
              approveRecordUsers.map(item => {
                if (item.type === 'APPROVER') {
                  this.approvalUser = {
                    label: item.user.nickName,
                    userid: item.user.id,
                    type: 'APPROVER',
                    avatarUrl: item.user.avatarUrl
                  }
                } else if (item.type === 'RECIPIENT') {
                  this.ccUser = {
                    label: item.user.nickName,
                    userid: item.user.id,
                    type: 'RECIPIENT',
                    avatarUrl: item.user.avatarUrl
                  }
                }
              })
            }
            this.getNotAllocationMoney()
            console.log(this.caseInfo)
            this.$toast.clear()
          } else {
            this.$notify('找不到数据')
          }
        })
        .catch(() => {
          this.$notify('调用接口出错，请稍后再试。')
        })
    },
    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var h =
        ' ' +
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':'
      var m =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D + h + m
    },
    // 计算未分的钱
    getNotAllocationMoney(item = null) {
      if (item) {
        if (this.sumSubShare() > this.caseInfo.apportionMoney) {
          item.money = 0
          this.$notify('不能超过未分金额')
        }
      }
      this.notAllocationMoney = Number(
        this.caseInfo.apportionMoney - this.sumSubShare()
      )
    },

    // 已分律师费求和
    sumSubShare() {
      let sum = 0.0
      this.feeList.forEach(item => {
        if (item.money && !item.ratio) {
          sum += Number(item.money)
        } else if (item.ratio && !item.money) {
          sum += Number((item.ratio / 100) * this.apportionMoney)
        }
      })
      return sum
    },
    // 获取协作人列表
    getItems() {
      this.$axios
        .get(
          `${this.$base}/management/organization/${
            this.organizationId
          }/users?page=0&size=1&key=`
        )
        .then(res => {
          if (res.data.data) {
            this.organiser = res.data.data.content[0]
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 添加费用分成人员
    addFeeList() {
      const temp = {
        caseFeeDivisionMode: 'QUOTA',
        ratio: null,
        money: 0,
        userId: null,
        type: 'LAWYER_EARNING',
        target: 'LAWYER'
      }
      temp.mark = `${Date.now()}${this.markNum}`
      this.feeList = [...this.feeList, temp]
    },

    // 删除费用分成人员
    deleteFeeList(item, index) {
      this.feeList.splice(index, 1)
      this.getNotAllocationMoney()
    },

    // 费用分成类型格式化
    feeTypeFmt(type) {
      const dict = {
        LAWYER_EARNING: '律师收入',
        LAWOFFICE_EARNING: '律所收入',
        LAWOFFICE_GUARANTY: '律所保证金',
        NO_APPORTION_EXPENSE: '不分成支出'
      }
      return dict[type]
    },

    // 收支类型点击事件
    switchFeeTypeList(item, i = -1) {
      this.feeTypeListVisible = !this.feeTypeListVisible
      if (i !== -1) {
        this.feeTypeSelect = item.type
        this.index = i
      }
    },
    handleFeeTypeListSelect(options) {
      this.feeList[this.index].type = options.value
      this.switchFeeTypeList()
    },

    // 协作人点击事件
    switchCoopList(item, i = -1) {
      this.coopListVisible = !this.coopListVisible
      if (i !== -1) {
        this.coopIdSelect = item.userId
        this.index = i
      }
    },
    handleCoopListSelect(options) {
      console.log(options)
      this.feeList[this.index].userId = options.id
      this.feeList[this.index].userName = options.nickName
      this.switchCoopList()
    },

    // 删除操作
    deleteCase() {
      this.$axios
        .delete(`${this.$base}/management/case/end/record/${this.caseId}`)
        .then(res => {
          if (res.data.code === 200) {
            this.$toast.success('删除成功')
            this.backList()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 点击保存事件
    save(progress) {
      if (progress === 'DELETE') {
        this.$dialog
          .confirm({
            title: '提示',
            message: '删除不可恢复！确定删除吗？'
          })
          .then(async () => {
            this.deleteCase()
          })
          .catch(() => {
            // on cancel
          })
      } else {
        const params = this.prepareData()
        params.progress = progress
        this.$axios
          .post(
            `${this.$base}/management/case/end/record/${this.userInfo.id}`,
            params
          )
          .then(res => {
            const { code, message } = res.data
            if (code === 200) {
              this.$toast.success(message)
              this.backList()
            }
          })
      }
    },

    // 准备数据
    prepareData() {
      const d = this.caseInfo
      d.isPaperArchive = d.isPaperArchive ? 'YES' : 'NO'
      d.isElectronicArchive = d.isElectronicArchive ? 'YES' : 'NO'
      d.caseFeeApportions = this.feeList
      d.organization = { id: this.organizationId }
      if (!d.approveRecordUsers) {
        d.approveRecordUsers = []
      }
      const tempUser = []
      if (this.isAdd === 1) {
        tempUser.push({
          type: 'APPROVER',
          user: {
            id: this.userInfo.id
          }
        })
      } else {
        tempUser.push({
          type: 'APPROVER',
          user: {
            id: this.approvalUser.userid
          }
        })
      }
      tempUser.push({
        type: 'RECIPIENT',
        user: {
          id: this.ccUser.userid
        }
      })
      tempUser.push({
        type: 'APPLICANT',
        user: {
          id: this.userInfo.id
        }
      })
      if (this.coopUser) {
        tempUser.push({
          type: 'APPROVER',
          user: {
            id: this.coopUser.id
          }
        })
      }
      tempUser.push({
        type: 'APPROVER',
        user: {
          id: this.approvalUser.userid
        }
      })
      d.approveRecordUsers = tempUser
      return d
    }
  },
  async created() {
    await this.getId()
    this.getUsers()
    this.getItems()
    this.id = this.$route.query.commonId
    this.isAdd = parseInt(this.$route.query.isAdd)
    // isAdd 0表示新增  1表示修改
    if (this.isAdd === 1) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.caseId = this.$route.query.caseId
      this.getCaseInfo(this.caseId)
    } else {
      this.switchCaseList()
    }
  },
  mounted() {}
}
</script>

<style lang="stylus">
.approval-title
  text-align center
  height 40px
  line-height 40px
  font-size 16px
  color rgba(69, 90, 100, 0.6)
.oa-caseInfo
  position relative
  padding-bottom 100px
  box-sizing border-box
  & .oa-caseInfo-line
    width 100%
    height 10px
    background rgba(242, 242, 242, 1)
  & .oa-caseInfo-caseName
    width 100%
    padding 10px 10px
    box-sizing border-box
    border-bottom 1px solid rgba(242, 242, 242, 1)
    font-size 16px
    font-weight 400
    div
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 1
      overflow hidden
  & .oa-caseInfo-caseInfo
    padding 10px
    box-sizing border-box
    & .caseInfo-casePirce
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      color #999
      font-size 14px
      padding 5px 0
      box-sizing border-box
    & .caseInfo-switch-box
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      padding 5px 0
      box-sizing border-box
      & > div
        display flex
        flex-direction row
        align-items center
        font-size 16px
  & .oa-caseInfo-partition
    padding 10px
    box-sizing border-box
    & .partition-title
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      & .partition-title-price
        display flex
        flex-direction row
        align-items center
        & .van-cell
          padding 0
          width 100px
      & div
        font-size 14px
      & .icon-add
        color #409EFF
    & .partition-content
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      margin-top 10px
      & .partition-content-user
        flex-direction column
        align-items start
        margin-right 10px
        & .img
          width 32px
          height 32px
          border-radius 16px
          background rgb(64, 158, 255)
          color #FFF
          line-height 32px
          text-align center
      & > div
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        font-size 14px
        & .van-cell
          padding-left 0px
          padding-right 0px
  & .oa-approval
    border-top 10px solid rgba(242, 242, 242, 1)
    padding 20px
    box-sizing border-box
    position relative
    & .oa-approval-title
      font-size 14px
      color #CCCCCC
    & .oa-approval-step
      height 80px
      padding 0 20px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      border-bottom 1px solid rgba(242, 242, 242, 1)
      font-size 14px
      & .oa-approval-step-div
        display flex
        flex-direction row
        align-items center
        & .oa-approval-step-dot
          width 10px
          height 10px
          border-radius 5px
          background rgba(242, 242, 242, 1)
          margin-right 10px
      & .oa-approval-userInfo
        display flex
        flex-direction column
        align-items flex-end
        img
          width 30px
          height 30px
          border-radius 15px
    & .oa-approval-step-line
      width 2px
      height 80px
      background rgba(242, 242, 242, 1)
      position absolute
      bottom 60px
      left 44px
  & .oa-caseInfo-bottom
    width 100%
    background #FFFFFF
    position fixed
    bottom 0px
    right 0px
    padding 10px 20px
    box-sizing border-box
    display flex
    flex-direction row
    justify-content flex-end
    button
      width 80px
      height 50px
      border-radius 5px
      font-size 16px
    & .oa-button-save
      background none
      border none
      color #0079FE
    & .oa-button-apply
      background #0079FE
      border none
      color #FFFFFF
    & .oa-button-delete
      background none
      border 1px solid #0079FE
      color #0079FE
</style>
